a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

@font-face {
    font-family: 'open-sansregular';
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open-sansextrabold';
    src: url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.eot');
    src: url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.woff') format('woff'),
         url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-extra-bold/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open-sansbold';
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot');
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}