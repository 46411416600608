.icon.banner-detalhe{
	background-position:0 -144px;
  	width:78px;
  	height:78px;
  	vertical-align: middle;
  	margin-right: 10px;
  	left: calc(50% - 39px);
}

.icon.detalhe-footer{
  background-position:0 -225px;
    width:78px;
    height:78px;
    vertical-align: middle;
    margin-right: 10px;
    left: calc(50% - 39px);
}

.icon.navcontrol-prev{
	background-position:0 -328px;
  	width:40px;
  	height:40px;
  	vertical-align: middle;
}

.icon.navcontrol-next{
	background-position:0 -370px;
  	width:40px;
  	height:40px;
  	vertical-align: middle;
}

.carousel-control-prev,
.carousel-control-next{
	width:40px;
	top: -130px!important;
  bottom: inherit;
}

.carousel-control-prev{
	right:80px;
	left:inherit;
}

.icon.facebook{
  background-position:0 -75px;
  width:32px;
  height:32px;
  vertical-align: middle;
}

.icon.instagran{
  background-position:0 -109px;
  width:32px;
  height:32px;
  vertical-align: middle;
}

.icon.phone{
  background-position:0 0;
  width:36px;
  height:38px;
  vertical-align: middle;
}

.icon.whatsapp{
  background-position:0 -38px;
  width:33px;
  height:35px;
  vertical-align: middle;
}