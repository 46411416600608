/*!
 * Lightbox 2.7.1
 * by Lokesh Dhakar
 * única modificação do lightbox: nome das imagens e caminho;
*/
$close-lightbox: '#{$imgs}/fechar.svg';

$loading-lightbox: '#{$imgs}/loading.svg';

$lightbox-prev: '#{$imgs}/prev.svg';

$lightbox-next: '#{$imgs}/next.svg';

body:after {
  content: url(#{$close-lightbox}) url(#{$loading-lightbox}) url(#{$lightbox-prev}) url(#{$lightbox-next});
  display: none;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity = 80);
  opacity: .8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: 400;
  .lb-image {
    display: block;
    height: auto;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  a img {
    border: 0;
  }
}

.lb-outerContainer {
  position: relative;
  background-color: #fff;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(#{$loading-lightbox}) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: 0;
}

.lb-next {
  width: 49%;
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-prev {
  width: 49%;
  height: 100%;
  cursor: pointer;
  display: block;
  left: 0;
  float: left;
  &:hover {
    background: url(#{$lightbox-prev}) left 48% no-repeat;
  }
}

.lb-next {
  right: 0;
  float: right;
  &:hover {
    background: url(#{$lightbox-next}) right 48% no-repeat;
  }
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-data {
  padding: 0 4px;
  color: #bbb;
  .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }
  .lb-caption {
    font-size: 13px;
    font-weight: 700;
    line-height: 1em;
  }
  .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999;
  }
  .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(#{$close-lightbox}) top right no-repeat;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity = 70);
    opacity: .7;
    &:hover {
      cursor: pointer;
      filter: alpha(Opacity = 100);
      opacity: 1;
    }
  }
}
