//colors
$black: #000;

//urls
$url_0: url(owl.video.play.png);

.owl-carousel {
  display: none;
  min-width: 100%;
  position: relative;
  z-index: 1;
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
  .owl-stage {
    position: relative;
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }
  .owl-stage-outer {
    position: relative;
    overflow: hidden;
  }
  .owl-controls {
    .owl-nav {
      .owl-prev {
        cursor: hand;
        //Instead of the line below you could use @include user-select($select)
        user-select: none;
      }
      .owl-next {
        cursor: hand;
        //Instead of the line below you could use @include user-select($select)
        user-select: none;
      }
    }
    .owl-dot {
      cursor: hand;
      //Instead of the line below you could use @include user-select($select)
      user-select: none;
    }
  }
  &.owl-loaded {
    display: block;
  }
  &.owl-loading {
    opacity: 0;
    display: block;
  }
  &.owl-hidden {
    opacity: 0;
  }
  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
    img {
      display: block;
      // width: 100%;
      //Instead of the line below you could use @include transform-style($style)
      transform-style: preserve-3d;
    }
    .owl-lazy {
      opacity: 0;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: opacity 400ms ease;
    }
  }
  .owl-grab {
    cursor: grab;
  }
  &.owl-rtl {
    direction: rtl;
    .owl-item {
      float: right;
    }
  }
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: $black;
  }
  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: $url_0 no-repeat;
    cursor: pointer;
    z-index: 1;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: scale 100ms ease;
    &:hover {
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: scale(1.3, 1.3);
    }
  }
  .owl-video-playing {
    .owl-video-tn {
      display: none;
    }
    .owl-video-play-icon {
      display: none;
    }
  }
  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: opacity 400ms ease;
  }
  .owl-video-frame {
    position: relative;
    z-index: 1;
  }
  .owl-refresh .owl-item {
    display: none;
  }
  &.owl-text-select-on .owl-item {
    //Instead of the line below you could use @include user-select($select)
    user-select: auto;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: height 500ms ease-in-out;
}
.no-js .owl-carousel {
  display: block;
}