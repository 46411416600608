.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

//BOXES

.card-servicos:hover{
	background-color: #310F05!important;
}

.card:hover{
	border:0px!important;
	box-shadow: none;
}

.paginacao .active, .paginacao :hover{
	background-color: #000000!important;
}

//BORDERS

@for $i from 1 through 30{
	.border-dark-yellow#{$i}{
		border: #A98F38 #{$i}px solid;
	}

	.border-dark-yellow-bottom#{$i}{
		border-bottom: #A98F38 #{$i}px solid;
	}

	.border-dark-yellow-top#{$i}{
		border-top: #A98F38 #{$i}px solid;
	}

	.border-dark-yellow-left#{$i}{
		border-left: #A98F38 #{$i}px solid;
	}

	.border-dark-yellow-right#{$i}{
		border-right: #A98F38 #{$i}px solid;
	}
}

//SIZES

@mixin size($size) {
	font-size: #{$size}px;
}

@for $i from 1 through 100{
	.size#{$i}{
		@include size($i);
	}
}

@media(max-width:767px){
	.text-center-responsive{
		text-align: center!important;
		margin-bottom:15px;
	}
	.menu{
		display:none!important;
	}
	.fa.fa-bars{
		display:block;
	}
}

/*menu hamburguer*/
.topo.menu-open .topo-content{
  left: 0;
  overflow: auto
}

.topo.menu-open {
	.bg-topo{
	  position: fixed;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.8);
	  left:0;
	  top:0;
	  z-index: 998;
	}

	& ~ .bg-brown .topo-content{
		left: 0;
	}

	& ~.bg-afrodecendente{
		position: fixed;
		left:0;
		top:0;
		width:100%;
		height: 100%;
		z-index: 998;
		background: rgba(#000,0.8);
	}
}

.topo-content{
  position: fixed;
  left:-250px;
  top:0;
  width: 250px;
  height: 100%;
  background: #FFF;
  border-right: 1px solid #Ccc;
  transition: left 0.6s linear;
  z-index: 999;
}

.topo-content .brand{
  padding: 30px 15px;
}

.topo-content #menu{
  list-style:none;
  padding-left:0;
  border-top:1px solid #ccc;
  font-family: 'open-sansregular';
  font-size: 16px;
  color: #06376B;
}

.topo-content #menu a{
  display: block;
  padding: 10px 15px;
  color: #000000!important;
}


.topo-content #menu li{
  border-bottom:1px solid #ccc;
}

.topo-content #menu .active a,
.topo-content #menu a:hover{
  color:  #D09933!important;
  font-family: open-sansextrabold
}

.menu-hamburguer{
	position: absolute;
	top: 10px;
    right: 10px;
	display: block;
	padding: 8px;
	border:1px solid #eeeeee;
	border-radius: 3px;
	z-index: 10;
	cursor: pointer;
	display: none;
	color: #FFFFFF
}

.item-menu-hamburguer{
	font-family: 'open-sansextrabold';
	font-size: 17px;
	color: #06376B!important;
}

//COLORS

.white{
	color: #ffffff!important;
}

.grayE{
	color: #eeeeee!important;
}

.grayD{
	color: #dddddd!important;
}

.grayC{
	color: #cccccc!important;
}

.grayB{
	color: #bbbbbb!important;
}

.grayA{
	color: #aaaaaa!important;
}

.gray9{
	color: #999999!important;
}

.gray8{
	color: #888888!important;
}

.gray7{
	color: #777777!important;
}

.gray6{
	color: #666666!important;
}

.gray5{
	color: #555555!important;
}

.gray4{
	color: #444444!important;
}

.gray3{
	color: #333333!important;
}

.gray2{
	color: #222222!important;
}

.gray1{
	color: #111111!important;
}

.black{
	color: #000000!important;
}

.orange{
	color: #D09933!important;
}



//BACKGROUNDS

.bg-brown{
	background-color: #5B4025!important;
}

.bg-dark-brown{
	background-color: #310F05!important;
}

.bg-extradark-brown{
	background-color: #24140D!important;	
}

.bg-topo{
	background-color: #1D191A!important;
}

.bg-dark-yellow{
	background-color: #D09A36!important;
}

.bg-dark-yellow2{
	background-color: #A98F38!important;
}
//JUSTIFYS

.display-flex{
	display:flex; 
}

.justify-betweeen{
	justify-content: space-between;
}

//WIDTHS

@for $i from 1 through 100{
	.width#{$i}p{
		width: percentage($i/100);
	}

	.max-width#{$i}p{
		max-width: percentage($i/100); 
	}
}

@for $i from 1 through 1500{
	.max-width#{$i}{
		max-width: #{$i}px; 
	}

	.width#{$i}{
		width: #{$i}px;
	}
}

//POSITIONS

.absolute{
	position: absolute;
}

.relative{
	position: relative;
}

.static{
	position: static;
}

.fixed{
	position: fixed;
}

@for $i from -100 through 1500{
	.top#{$i}{
		top: #{$i}px;
	}

	.bottom#{$i}{
		bottom: #{$i}px;
	}

	.left#{$i}{
		left: #{$i}px;
	}

	.right#{$i}{
		right: #{$i}px;
	}
}

//MARGINS
.margin-auto{
	margin-left: auto;
	margin-right: auto;
}
@for $i from -100 through 300{
	$increment:$i+5;

	//MARGINS
	.margin#{$i}p{
		margin: percentage($i/100);
	}

	.margin-left#{$i}p{
		margin-left: percentage($i/100);
	}

	.margin-right#{$i}p{
		margin-right: percentage($i/100);
	}

	.margin-top#{$i}p{
		margin-top: percentage($i/100);
	}

	.margin-bottom#{$i}p{
		margin-bottom: percentage($i/100);
	}

	.margin-side#{$i}p{
		margin-left: percentage($i/100);
		margin-right: percentage($i/100);
	}

	.margin-tb#{$i}p{
		margin-top: percentage($i/100);
		margin-bottom: percentage($i/100);
	}

	.margin#{$increment}{
		margin: #{$increment}px;
	}

	.margin-side#{$increment}{
		margin-left: #{$increment}px;
		margin-right: #{$increment}px;
	}

	.margin-tb#{$increment}{
		margin-top: #{$increment}px;
		margin-bottom: #{$increment}px;
	}

	.margin-left#{$increment}{
		margin-left: #{$increment}px;
	}
	
	.margin-right#{$increment}{
		margin-right: #{$increment}px;

	}

	.margin-top#{$increment}{
		margin-top: #{$increment}px;
	}
	
	.margin-bottom#{$increment}{
		margin-bottom: #{$increment}px;
	}

	//PADDINGS
	.padding#{$increment}{
		padding: #{$increment}px;
	}

	.padding-side#{$increment}{
		padding-left: #{$increment}px;
		padding-right: #{$increment}px;
	}

	.padding-tb#{$increment}{
		padding-top: #{$increment}px;
		padding-bottom: #{$increment}px;
	}

	.padding-tb#{$increment}{
		padding-top: #{$increment}px;
		padding-bottom: #{$increment}px;
	}

	.padding-left#{$increment}{
		padding-left: #{$increment}px;
	}
	
	.padding-right#{$increment}{
		padding-right: #{$increment}px;
	}

	.padding-top#{$increment}{
		padding-top: #{$increment}px;
	}
	
	.padding-bottom#{$increment}{
		padding-bottom: #{$increment}px;
	}
}
//ICONS
	.icon{
  	background-image:url(../imagens/estrutural/sprites.png);
	background-repeat:no-repeat;
	display:inline-block;  
	background-position:center center;
}

//FONTS

.open-sans{
	font-family:'open-sansregular';
}

.open-sansextrabold{
	font-family: 'open-sansextrabold';
}

.open-sansbold{
	font-family: 'open-sansbold';
}

//BUTTONS

.btn-black{
	background-color: #000000!important;
}

.btn-dark-yellow{
	background-color: #D09A36!important;
}

.bg-dark-yellow:hover{
	background-color: #A98F38!important;
}

.menu{
	display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.menu .menu-item a{
	color: #FFFFFF;
	font-family: 'open-sansextrabold';
	font-size: 14px
}

.menu .menu-item.active a{
	color: #E0A638;
}